html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.navHeader{
  display: flex;
  justify-content: right;
  flex-direction: row;
  background-color: #3B4A89;
  width: 100%;
  gap: 1vw;
  margin-bottom: 0.2vw;
}

.navHeader span {
  margin: 0.5vw 0.5vw;
  align-items: center;
  display: flex;
}

.navHeader span img{
  width: 75%;
}

header{
  display: flex;
  flex-direction: row;
  min-height: 6vw;
  max-height: 6vw;
}

.logo{
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img{
  width: 13.5vw;
  height: auto;
}

nav{
  width: 50%;
}

.contact{
  width: 30%;
}

nav ul{
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  height: 100%;
  align-items: center;
  margin: 0;
}

nav ul li a{
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.1vw;
}

nav ul li a:link, nav ul li a:visited{
  color:#1B1C1A;
}

nav ul li a:hover{
  color:#3B4A89;
  font-weight: 500;
  padding-bottom: 0.4vw;

  border-bottom-style: solid;
  border-bottom-width: 0.2vw;
  border-color: #3B4A89;
}

.contact{
  justify-content: right;
  display: flex;
}

.contact .banner {
  width: 28vw;
  height: 0px;
  border-right: 0px solid transparent;
  border-left: 2.5vw solid transparent;
  border-bottom: 6.5vw solid #3B4A89;

}

.contactText{
  position: relative;
  margin-top: 1.4vw;
  margin-left: 1vw;
}

.contactText p{
  margin: 0;
  color: #fff;
}

.contactText::before{
  content: url(../public/img/tel.png);
  position: absolute;
  left: -0.5em;
  top: 0vw;
}

.contactText .title{
  font-size: 1.4vw;
  font-weight: bold;
}

.contactText .numbers{
  font-size: 1.3vw;
  line-height: 2.5vw;
}

.slide img{
  width: 100%;
  height: auto;
}

.slide {
  display: flex;
  color: #fff;
  position: relative;
  font-size: 2.2vw;
  font-weight: bold;
  min-height: 40vw;
  background-image: url(../public/img/s1.jpg);
  background-size: cover;
}

.slide .container {
  position: absolute;
  left: 2vw;
  bottom: 3vw;
}

.slide .container p{
  margin: 5px 15px;
  background-color: rgb(107, 107, 107, 0.7);
  padding: 1vw;
}

.block1{
  display: flex;
  flex-direction: row;
  text-align: center;
}

.block1 > div {
  display: flex;
  align-items: center;
  min-height: 30vw;
  justify-content: center;
  padding: 2vw 4vw;
}

.block1 .title{
  width: 35%;
  font-weight: lighter;
  font-size: 3vw;
  background-color: #1B1C1A;
  color: #fff;
}

.block1 .desc{
  width: 65%;
  padding-right: 15vw;
  background-color: #272726;
  color: #fff;
  font-size: 2vw;
}

.block2{
  display: flex;
  flex-direction: row;
}

.block2 img{
  width: 100%;
  height: auto;
}

.block2 .devices{
  width: 30%;
  display: flex;
  align-items: center;
}

.block2 .desc{
  display: flex;
  width: 30%;
  align-items: center;
}

.block2 .promo{
  width: 40%;
  display: flex;
  align-items: end;
}

footer{
  background-color: #1B1C1A;
  text-align: center;
  padding:1vw 0;
  border-top-color: #888;
  border-top-width: 0.7vw;
  border-top-style: solid;
}

footer .logoFooter img{
  width: 15vw;
}

footer .copyright{
  color: #fff;
  font-weight: lighter;
}

.contactoA h5{
  font-size: 3em; 
  text-align: center; 
  padding: 12vw 9vw;
}

.titleBlock2{
  background-color: #3B4A89;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 1vw;
  color:#fff;
  font-size: 1.3vw;
}

.block2 .devices, .block2 .desc, .block2 .promo{
  flex-direction: column;
}

.block2 .desc ul{
  margin-top: 5vw;
  font-size: 1.3vw;
}

.copyright p{
  font-size: 1.1vw;
}

.contactInfo .social img{
  width: 3vw;
}

.menuButton{
  background-color: #cecece;
    padding: 0.5vw 1vw;
    border-radius: 5vw;
}

.menuButton:hover{
  background-color: #3B4A89;
  color:#fff;

}

nav .menuButton a:hover{
  border: none;
  color:#fff;
}

.contactInfo .social{
  display: flex;
  align-items: center;
}

.btnAccess{
  padding: 1vw 3vw;
  margin: 1vw 2vw;
  background-color: #3B4A89;
  color: #fff;
  text-align: center;
  font-weight: 500;
  border-radius: 5vw;
  cursor: pointer;
  text-decoration: none;
}

.btnAccess:hover{
  background-color: #1B1C1A;
}