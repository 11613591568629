@media only screen and (max-width: 768px) {
    nav.menu{
        display: none;
    }
    header .logo{
        width: 50%;
    }
    header .contact{
        width: 50%;
    }
    .logo img {
        width: 80%;
        height: auto;
    }
    .contact .banner {
        width: 50vw;
    }
    .contactText {
        position: relative;
        margin-top: 2.4vh;
        margin-left: 3vw;
    }
    .contactText .numbers {
        font-size: 3vw;
        line-height: 3.2vh;
    }
    
    .contactText::before {
        top: -2vh;
    }
    .contactText .title {
        font-size: 3.3vw;
    }
    .slide {
        font-size: 1.4em;
        min-height: 60vh;
        background-image: url(../public/img/movil1.jpg);
    }
    .block1 {
        flex-direction: column;
    }
    .block1 .title {
        width: 100%;
        font-size: 2em;
    }
    .block1 .desc {
        width: 100%;
        padding: 15vw 12vw;
        font-size: 1.3em;
        justify-content: center;
    }
    .block2 {       
        flex-direction: column;
    }
    .block2 .devices, .block2 .desc, .block2 .promo{       
        width: 100%;
        justify-content: center;
    }
    .contactBanner > div {
        min-height: 30vw;
    }
    .contactBanner {
        flex-direction: column !important;
    }

    .contactBanner .title{
        width: 100% !important;
        font-size: 2em !important;
    }

    .contactBanner .desc {
        width: 100% !important;
        font-size: 1.3em !important;
        padding: 15vw 12vw !important;
    }

    .contactBanner > div {
        min-height: 15vw !important;    
        padding: 10vw 4vw !important;
    }

    .formContainer {
        flex-direction: column !important;
    }
    .formContainer > div {
        width: 100% !important;
    }
    .contactInfo {
        padding: 8vw 3vw !important;
    }
    .contactInfo h5 {
        font-size: 2.3em !important;
    }
    .contactInfo p, .contactInfo a  {
        font-size: 1.6em !important;
    }
    .distBox{
        margin-top: 3em !important;
    }
    footer .logoFooter img {
         width: 10em !important;
    }
    .nextBtn {
        height: 4rem !important;
        font-size: 1.5em !important;
    }

    header {
        min-height: 17vw;
        max-height: 17vw;
    }

    .contact .banner {
        border-bottom: 17vw solid #3B4A89;
    }

    .navHeader span {
        margin: 2.5vw 0.5vw;
    }

    .titleBlock2 {
        font-size: 5.3vw;
    }

    .block2 .desc ul {
        font-size: 4.3vw;
    }

    .block2 .desc ul {
        margin-bottom: 5vw;
        margin-top: 5vw;
    }

    .contactInfo .social img {
        width: 10vw;
    }

    .contactInfo .social{
        gap: 10vw !important;
    }

    .contactInfo p.tel span::before {
        left: -10vw !important;
        top: 2px !important;
    }

    .input_title {
        font-size: 5.7vw !important;
        height: 9vw !important
    }
    .form-box input {
        height: 12vw !important;
    }

    .form-box .userIcon img {
        width: 5.5vw !important;
    }

    .form-box .userIcon {
        position: relative;
        top: 2.7vw !important;
        left: 2vw !important;
    }

    .form-box textarea {
        height: 35vw !important;
    }

    .form-box input, textarea, select {
        font-size: 5.7vw !important;
    }

    .copyright p {
        font-size: 3.5vw !important;
    }

    .titleBlock2 {
        padding: 5vw !important;
    }

    .contactInfo h2 {
        font-size: 4.3vw !important;
        margin-bottom: 5vw;
    }

    .formContainer .logosDist img {
        width: 25% !important;
    }

    .btnAccess {
        padding: 5vw 18vw !important;
        margin: 7vw 2vw !important;
    }
}

@media only screen and (max-width: 1200px) {

}